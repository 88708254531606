.balanceWrapper {
  background-color: var(--yellow);
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: rgb(255, 205, 4) 0px 10px 20px -10px;
  font-size: 14px;
  margin-left: 25px;
}
.balanceWrapper span {
  margin: 0 10px;
}
