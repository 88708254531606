.loginPageContainer {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginFormWrapper {
  background-color: white;
  padding: 25px;
  border-radius: 15px;
  text-align: center;
  width: 400px;
}
.loginFormTitle {
  font-weight: 600;
  color: var(--dark-blue);
  margin-bottom: 15px;
}
.loginFormSubtitle {
  font-weight: 600;
  color: var(--dark-gray);
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 50px;
}
.submitBtn {
  width: 100%;
  height: 50px;
  background-color: var(--primary-blue);
  border: none;
  border-radius: 5px;
  box-shadow: 0 10px 20px -10px #1b55e2;
  color: white;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: ease-in-out 0.4s;
  margin-top: 10px;
}
.submitBtnDisabled {
  opacity: 30%;
  background-color: var(--dark-gray);
}
.submitBtn:hover {
  transform: translateY(-4px);
  box-shadow: none;
}
.linksContainer {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.link {
  font-weight: 700;
  color: var(--primary-blue);
  font-size: 14px;
}
.icon,
.eyeIcon {
  fill: rgba(0, 23, 55, 0.08);
  width: 20px;
  height: 20px;
  color: var(--dark-gray);
  position: absolute;
}
.icon {
  bottom: 11px;
  left: 15px;
}
.eyeIcon {
  bottom: 11px;
  right: 15px;
  cursor: pointer;
}
.form {
  position: relative;
}
.modalContent {
  width: 500px;
  height: 350px;
  border-radius: 6px;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 20px;
  text-align: center;
}
.subHeader {
  color: #3b3f5c;
  font-weight: 700;
  margin-bottom: 15px;
}
.displayMessage {
  font-weight: 600;
  height: 25px;
}
.errorMessage {
  color: var(--red);
}
.successMessage {
  color: var(--green);
}
