.settlementScreenContainer {
  height: 655px;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 3rem 1.5rem 0.5rem 2rem;
  box-shadow: 0 4px 6px rgba(85, 85, 85, 0.08), 0 1px 20px rgba(0, 0, 0, 0.07),
    0 1px 11px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}

@media screen and (max-width: 800px) {
  .settlementScreenContainer {
    flex-direction: column;
    padding-bottom: 40px;
  }

  .settlementScreenContainer > * {
    height: 50%;
  }
}

.messageContainer {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.statusMessage {
  align-self: center;
  justify-self: center;
  box-sizing: border-box;
  margin-left: 1rem;
  height: 42px;
  width: 22rem;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 1.5rem;
  padding: 1rem 2rem;
}

.errorMessage {
  border: 1px solid red;
  background: lightpink;
}

.successMessage {
  border: 1px solid #319317;
  background: #94e596;
}

.settlementContainer {
  display: flex;
  /*flex-direction: column;*/
  justify-content: space-evenly;
}
