.submenuContainer {
  width: 100%;
  margin-bottom: 20px;
}
.subMenuHeader {
  display: flex;
  height: 37px;
  width: 100%;
}
.iconContainer {
  display: flex;
  height: 20px;
  width: 20px;
  background-color: transparent;
  margin: 8px 12px 8px 24px;
}
.headerText {
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  color: #333333;
  padding: 8px 0;
}
.itemText {
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  padding: 8px 0;
}
.subMenuItem {
  display: flex;
  height: 37px;
  width: 100%;
  cursor: pointer;
}

.active {
  background: rgba(249, 171, 19, 0.1);
  /* box-shadow: inset -2px 0px 0px #f9ab13; */
  font-weight: 700;
}

.active > div {
  color: #f9ab13;
}
