.sidebar {
  width: 150px;
  height: calc(100vh - 90px);
  background-color: var(--yellow);
  z-index: 2;
  position: relative;
}
.sidebarExtension {
  position: absolute;
  top: 90px;
  height: calc(100vh - 90px);
  width: 240px;
  left: -250px;
  z-index: 1;
  background-color: #faf7ea;
  transition: ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
}
.sidebarExtensionOpen {
  left: 150px;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.sidebarLink {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 110px;
  background-color: var(--yellow);
  transition: ease-in-out 0.2s;
}

.linkIcon {
  fill: var(--transparent-gray);
  stroke-width: 0.5px;
  height: 46px;
  width: 46px;
}
.sublinkIcon {
  stroke-width: 1px;
  fill: var(--transparent-gray);
  height: 24px;
  width: 24px;
}
.sidebarLink p {
  font-size: 15px;
  margin-top: 5px;
  font-weight: 500;
}
.sidebarLinkActive {
  background-color: var(--yellow-selected);
}
.contentWrapper {
  display: flex;
  background-color: var(--light-gray);
}
.subLinksContainer {
  display: none;
  flex-direction: column;
  margin: 20px 0 0 10px;
}
.subLinksContainerOpen {
  display: flex;
}
.subLink {
  transition: ease-in-out 0.2s;
  display: flex;
  padding: 7px 15px;
  align-items: center;
  width: 80%;
  border-radius: 5px;
  margin: 5px 0;
}
.subLink:hover {
  background-color: var(--yellow);
}
.subLink p {
  margin: 0 0 0 10px;
}
.contentContainer {
  width: calc(100vw - 150px);
  padding: 20px;
}
.parentContentContainer {
  width: 100%;
}
.closeExtension {
  display: none;
}
@media screen and (max-width: 800px) {
  .contentWrapper {
    flex-direction: column-reverse;
    overflow-x: hidden;
  }
  .contentContainer {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    z-index: 0;
  }
  .sidebar {
    width: 100%;
    display: flex;
    height: 75px;
    overflow-x: scroll;
    overflow-y: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
  .linkIcon {
    width: 24px;
    height: 24px;
  }
  .sidebarLink p {
    margin: 5px 0 0 0;
    font-weight: 900;
    text-align: center;
    white-space: nowrap;
    font-size: 12px;
  }
  .sidebarLink {
    height: 100%;
    margin: 0 30px;
    min-width: 70px;
  }
  .sidebarExtension {
    position: fixed;
    bottom: 75px;
    left: -500px;
    width: 100%;
    height: fit-content;
    top: auto;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 1000;
  }
  .sidebarExtensionOpen {
    z-index: 1000;
    bottom: 75px;
    height: 250px;
    left: 0;
  }
  .closeExtension {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .sidebarLinkActive {
    background-color: var(--yellow);
  }
}
