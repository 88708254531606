.modalContent {
  width: 1100px;
  height: 640px;
  border-radius: 6px;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 20px;
}
.modalContent:focus-visible {
  outline: none;
}
.badgesContainer {
  display: flex;
  flex-direction: column;
}
.badge {
  margin-right: 15px;
  background-color: var(--gray);
  color: rgb(102, 102, 102);
  font-weight: 900;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  transition: ease-in-out 0.1s;
}
.badgeActive {
  background-color: var(--primary-blue);
  color: white;
}
.badgesContainer p,
.modalSelectContainer p {
  color: var(--dark-blue);
  font-weight: 700;
  font-size: 15px;
}
.modalGrid {
  display: grid;
  column-gap: 30px;
}
.modalTitle h1,
.modalSubtitle {
  font-size: 24px;
  color: var(--dark-blue);
  font-weight: normal;
}
.modalTitle h1 {
  margin: 5px 0;
}
.modalTitle {
  border-bottom: 1px solid var(--gray);
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.modalSubtitle {
  margin-top: 10px;
}
.modalFooter {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}
.tableIcons svg {
  cursor: pointer;
  margin-right: 5px;
}
.tableIcons:hover svg {
  color: #e7515a;
  fill: rgba(231, 81, 90, 0.219608);
}
.modalSelectContainer {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .modalContent {
    width: 90%;
    height: 90%;
    padding: 10px 10px;
    outline: none !important;
  }
}
