.inputCalendar {
  position: absolute;
  right: 15px;
  top: 13px;
  width: 16px;
  fill: var(--light-gray);
  stroke-width: 0.5;
}
.dateInputLabelOnBorder {
  padding: 1px 8px;
  margin-left: -4px;
  color: rgba(0, 0, 0, 0.6);
  background-color: white;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 0;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datePickerContainer,
.parentPickerContainer {
  box-sizing: border-box;
  position: relative;
  font-size: 15px;
  width: 31rem;
}

.parentPickerContainer {
  margin-top: 2rem;
  z-index: 1;
}

.settlementButton {
  width: 31rem;
  height: 3.2rem;
  margin-top: 1rem;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.parentSettlementContainer {
  margin-left: 1rem;
}

.submitButtonContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loadingIcon {
  margin-top: 1rem;
  position: absolute;
  right: -2.5rem;
}

.settlementDoneInfo {
  text-align: center;
  font-weight: bold;
  margin: 1rem 0;
}
