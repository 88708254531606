.searchBar {
  border-radius: 5px;
  border: none;
  box-shadow: 0px 4px 24px 0px rgba(193, 193, 193, 0.4);
  padding: 15px 45px 15px 15px;
  box-sizing: border-box;
  width: 170px;
  height: 50px;
  font-size: 15px;
}
.searchBar:focus {
  outline: none;
}
.searchIcon {
  position: absolute;
  top: 13px;
  right: 0;
}
.searchIconFullWidth {
  right: 30px;
}
.searchContainer {
  position: relative;
  width: 150px;
}
.searchContainerFullWidth {
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}
.searchBarFullWidth {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .searchBar {
    border: 1px solid var(--light-gray);
    box-shadow: none;
  }
}
