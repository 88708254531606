.textarea {
  width: 100%;
  border-radius: 8px;
  padding: 15px;
  box-sizing: border-box;
  height: 220px;
  font-size: 15px;
  resize: none;
  border: 1px solid var(--gray5);
  outline: none;
}
.textarea:focus {
  outline: none;
}
.btnsContainer {
  display: flex;
  float: right;
  margin-top: 50px;
  height: 45px;
}
.inputRow {
  display: flex;
  width: 100%;
  color: var(--primary-blue);
  align-items: center;
}
.inputRow svg {
  margin-right: 10px;
}
