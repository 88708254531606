.userChipContainer {
  display: flex;
  background-color: var(--yellow);
  width: fit-content;
  border-radius: 30px;
  height: 45px;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-right: 25px;
}
.title {
  margin-left: 10px;
  font-size: 14px;
  color: #414141;
}
.iconContainer {
  border-radius: 100%;
  background-color: #656565;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
}
.popupContainer {
  position: absolute;
  top: 60px;
  right: 30px;
  width: 300px;
  pointer-events: none;
  opacity: 0;
  transition: ease-in-out 0.2s;
}
.popupActive {
  display: block;
  opacity: 1;
  pointer-events: all;
}
.popupTop {
  background-image: linear-gradient(
    to top,
    #ffe98e 0%,
    #ffcd04 52%,
    #ffcd04 100%
  );
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 8px 8px 0 0;
}
.popupBottom {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  align-items: center;
  padding: 3px 0;
}

.optionContainer {
  height: 40px;
  display: flex;
  align-items: center;
  width: 85%;
  border-radius: 8px;
  padding: 2px 0 2px 10px;
  border-bottom: 1px solid var(--gray);
}
.optionContainer:hover {
  background-color: var(--light-gray);
}
.popupText {
  margin-left: 10px;
}
.popupText p {
  font-size: 14px;
}
.popupText h3,
.popupText p {
  margin: 5px 0;
}
.popupIcon {
  fill: rgb(255 205 4 / 38%);
  color: var(--dark-gray);
  width: 17px;
  stroke-width: 1.5;
  margin-right: 10px;
}
.popupContainer:first-child {
  border-bottom: 1px solid black;
}
.optionContainer p {
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-blue);
}

@media screen and (max-width: 800px) {
  .title {
    display: none;
  }
  .userChipContainer {
    width: auto;
  }
  .popupContainer {
    right: 10px;
  }
}

.logoutLink {
  display: flex;
  align-items: center;
}
