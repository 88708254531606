.input {
  height: 40px;
  border-radius: 3px;
  border: 1px var(--gray5) solid;
  width: 100%;
  padding: 12px 0 12px 20px;
  box-sizing: border-box;
  overflow: hidden;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  color: var(--gray1);
}

.inputError {
  border: 1px solid red;
}
.inputWithIcon {
  padding-left: 45px;
}
.inputWithEndAdornment {
  padding-right: 45px;
}
.input::placeholder {
  font-weight: 700;
}
.input:focus {
  box-shadow: 0 0 5px 2px rgb(194 213 255 / 62%);
  border-color: #1b55e2;
  outline: none;
}
.inputGroupWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 25px 0;
  position: relative;
}

.label {
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--gray3);
  font-weight: 400;
  line-height: 21px;
}

.inputRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.link {
  color: var(--dark-gray);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
}
.errorMessage {
  color: red;
  position: absolute;
  top: 57px;
}
.modalInput {
  height: 50px;
  padding: 0 15px;
  font-size: 15px;
}
.modalInput::placeholder {
  font-weight: normal;
}
.modalInputGroupWrapper {
  margin: 15px 0;
}
.modalLabel {
  position: absolute;
  padding: 1px 8px;
  margin-left: -4px;
}
.chatLabel {
  display: none;
}
.chatInputGroupWrapper {
  margin: 0;
}
.chatInput {
  height: 50px;
  padding-left: 40px;
  border-radius: 0 0 8px 0;
}
