body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea,
.MuiOutlinedInput-root,
.MuiMenuItem-root {
  font-family: 'Quicksand', sans-serif !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --light-gray: #f1f2f3;
  --dark-gray: #888ea8;
  --dark-blue: #3b3f5c;
  --gray: #e0e6ed;
  --gray1: #333333;
  --gray3: #828282;
  --gray5: #e0e0e0;
  --primary-blue: #1b55e2;
  --yellow: #fbcd32;
  --green: #8dbf42;
  --red: #e7515a;
  --yellow-selected: #f9ab13;
  --transparent-gray: rgba(6, 8, 24, 0.0784314);
}
a {
  text-decoration: none;
  color: black;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 3px !important;
  border: var(--gray5) 1px solid !important;
}
.rmdp-container {
  width: 100%;
}
.MuiSelect-select {
  color: var(--gray1) !important;
}
