.title {
  color: var(--dark-blue);
  font-size: 18px;
  font-weight: 700;
  margin: 5px 0;
}
.annoucementContainer h3 {
  color: var(--dark-gray);
  font-weight: 600;
  margin: 0;
  font-size: 12px;
}
.annoucementContainer {
  position: relative;
  padding: 10px;
  border-bottom: 1px solid var(--gray);
  display: flex;
  justify-content: space-between;
  z-index: 0;
}
.optionsContainer {
  box-shadow: 3px 7px 18px 0px rgba(66, 68, 90, 0.32);
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 100px;
  right: 30px;
  justify-content: center;
  visibility: hidden;
}
.option {
  color: var(--dark-gray);
  font-weight: 900;
  font-size: 14px;
  width: 100px;
  transition: ease-in-out 0.2s;
  padding: 8px;
  margin: 5px 0;
  border-radius: 8px;
}
.option:hover {
  background-color: var(--gray);
}
.active {
  visibility: visible;
}
.annoucementInfo {
  cursor: pointer;
  max-width: 90%;
}
