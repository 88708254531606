.headerContainer {
  margin: 20px 0 20px 20px;
}
.header {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.description {
  font-size: 14px;
  line-height: 21px;
  color: #828282;
}

.header,
.description {
  margin: 0 0 8px 0;
}
.tabContainer {
  width: calc(100% - 440px);
  padding: 4px 20px;
}

@media screen and (max-width: 800px) {
  .tabContainer {
    width: 100%;
    overflow-y: scroll;
  }
  .datePickerContainer,
  .selectContainer {
    width: 100%;
  }
}
