.topbarContainer {
  height: 90px;
  box-shadow: 0 4px 6px 0 rgb(85 85 85 / 8%), 0 1px 20px 0 rgb(0 0 0 / 7%),
    0px 1px 11px 0px rgb(0 0 0 / 7%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 99;
  position: relative;
}
.topBarLeftContainer {
  display: flex;
  align-items: center;
}
.logo {
  width: 110px;
}
.selectContainer {
  box-sizing: border-box;
  padding: 0 25px;
  margin: 14px 0 5px 10px;
  width: 500px;
  font-size: 15px;
}
.userIcon {
  position: absolute;
  left: 22px;
  color: var(--gray1);
  fill: var(--gray);
  stroke-width: 0.8;
  top: 15px;
}
.topbarRight {
  display: flex;
  align-items: center;
}
.topbarSelect {
  box-sizing: border-box;
  padding-left: 50px;
  width: 400px;
}
.messagesBtn {
  background-color: var(--dark-blue);
  border: none;
  color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 45px;
  padding: 0 20px;
  font-size: 15px;
  margin-right: 24px;
  cursor: pointer;
}
.messagesCounter {
  background-color: white;
  border-radius: 8px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  color: black;
}
.mobileChatIcon {
  display: none;
}
.blueInfo,
.redInfo {
  color: white;
  padding: 1rem;
  border-radius: 4px;
  margin-top: 10px;
}
.blueInfo {
  background-color: var(--primary-blue);
}
.redInfo {
  background-color: var(--red);
}
.btnsContainer {
  display: flex;
  align-self: end;
  height: 40px;
  float: right;
  margin-top: 50px;
}
.valueInfo {
  font-size: 14px;
  font-weight: bold;
  margin-top: -5px;
}

@media screen and (max-width: 800px) {
  .topbarSelect {
    padding-left: 50px;
    width: auto;
    font-size: 0 !important;
    height: 55px;
  }
  .messagesBtn {
    margin-right: 0;
    position: fixed;
    top: 80px;
    width: 60px;
    height: 60px;
    right: 12px;
    border-radius: 30px;
  }
  .messagesBtn span {
    display: none;
  }
  .messagesCounter {
    position: absolute;
    right: 0;
    top: -6px;
    border-radius: 100%;
    font-weight: bold;
  }
  .mobileChatIcon {
    display: block;
  }
}
