.title {
  border-bottom: var(--yellow) 2px solid;
  font-weight: 500;
  font-size: 25px;
  color: var(--dark-grey);
  padding-bottom: 5px;
  margin: 0;
  width: fit-content;
}
.titleWrapper {
  width: 100%;
  border-bottom: 1px solid #e0e6ed;
  margin-bottom: 30px;
}
