.subLink {
  transition: ease-in-out 0.2s;
  display: flex;
  padding: 7px 15px;
  align-items: center;
  width: 80%;
  border-radius: 5px;
  margin: 5px 0;
}
.subLink:hover {
  background-color: #fbcd32;
}
.subLink p {
  margin: 0 0 0 10px;
}
