.tableTop {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
}
.tableContainer {
  height: 700px;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 30px 50px;
}
@media screen and (max-width: 800px) {
  .tableContainer {
    height: 450px;
    padding: 30px 15px;
  }
}
