.formContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.inputCalendar {
  position: absolute;
  right: 15px;
  top: 13px;
  width: 16px;
  fill: var(--light-gray);
  stroke-width: 0.5;
}

.dateInputLabelOnBorder {
  padding: 1px 8px;
  margin-left: -4px;
  color: rgba(0, 0, 0, 0.6);
  background-color: white;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectContainer {
  box-sizing: border-box;
  display: inline-block;
  padding: 0 25px;
  margin: 14px 0 5px 0;
  width: 50%;
  font-size: 15px;
}

.select {
  height: 46px;
  margin: 10px 0;
  width: 100%;
  padding-left: 10px;
  font-size: 15px !important;
}

.selectLabel {
  font-size: 15px;
  color: var(--gray3);
}

.datePickerContainer {
  box-sizing: border-box;
  position: relative;
  padding: 0 25px;
  width: 50%;
  font-size: 15px;
}

.submitBtnContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-left: 25px;
}

.loadingIcon {
  margin-left: 1rem;
}

.submitBtn {
  height: 38px;
  font-size: 14px;
  font-weight: normal;
}

.errorMessage {
  margin-left: 1.5rem;
  height: 38px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: normal;
  border: 1px solid red;
  border-radius: 5px;
  background: lightpink;
  margin-top: 2rem;
  padding: 0 2.125rem;
}

.switchList {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
}

.switchContainer {
  display: flex;
  align-items: center;
}

.switchLabel {
  font-weight: 500;
  color: var(--gray3);
}

.switchTrack {
  background-color: var(--yellow) !important;
}

.switchThumb {
  color: var(--yellow-selected);
}

@media screen and (max-width: 800px) {
  .datePickerContainer,
  .selectContainer {
    width: 100%;
  }
}
