.headerContainer {
  margin: 0px 0px 24px 0px;
}
.header {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.description {
  font-size: 14px;
  line-height: 21px;
  color: #828282;
}

.header,
.description {
  margin: 0px 0px 8px 0px;
}
.menuContainer {
  width: 350px;
  padding: 24px;
  overflow-y: scroll;
}
.menuContainer::-webkit-scrollbar {
  width: 10px;
}
.menuContainer::-webkit-scrollbar-thumb {
  background-color: var(--gray);
  border-radius: 8px;
}
