.btn {
  background-color: var(--primary-blue);
  border: none;
  box-shadow: 0 10px 20px -10px var(--primary-blue);
  color: white;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 30px;
  font-size: 14px;
  font-family: 'Quicksand';
  cursor: pointer;
  font-weight: 600;
  transition: ease-in-out 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn:hover {
  box-shadow: none;
  transform: scale(1.03);
}
.btnWhite {
  background-color: white;
  color: var(--primary-blue);
}
.btnWhite:hover {
  background-color: var(--primary-blue);
  color: white;
}
.btnGreen {
  background-color: var(--green);
}
.btnDisabled {
  background-color: var(--gray3);
  border: none;
  box-shadow: 0 10px 20px -10px var(--gray3);
  color: white;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 5px 30px;
  font-size: 14px;
  font-family: 'Quicksand';
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
