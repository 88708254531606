.cardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 155px;
  border-radius: 8px;
  transition: ease-in-out 0.2s;
  cursor: pointer;
}
.cardWrapper:hover {
  transform: scale(1.025);
}
.iconContainer {
  background-color: white;
  border-radius: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}
.green {
  background-color: var(--green);
}
.red {
  background-color: var(--red);
}
.blue {
  background-color: #2196f3;
}
.orange {
  background-color: #e2a03f;
}
.green svg {
  color: var(--green);
}
.red svg {
  color: var(--red);
}
.blue svg {
  color: #2196f3;
}
.orange svg {
  color: #e2a03f;
}
.cardText {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  margin: 0;
}
.cardCounter {
  font-weight: 600;
  font-size: 26px;
  margin: 10px 0 3px 0;
}

@media screen and (max-width: 800px) {
  .cardWrapper {
    margin: 10px 0;
  }
}
