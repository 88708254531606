.annoucementsWrapper {
  border-radius: 8px;
  background-color: white;
  display: flex;
  height: 100%;
  overflow: hidden;
}
.annoucementsSidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 20%;
  border-right: 1px solid var(--gray);
  height: 100%;
  padding: 25px 0;
  box-sizing: border-box;
}
.sidebarTop {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.topChip {
  background-color: #ebedf2;
  color: var(--primary-blue);
  font-weight: 600;
  align-self: flex-start;
  width: 90%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 0 16px 16px 0;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.topChip p {
  margin: 0 0 0 15px;
}
.annoucementsRight {
  height: 100%;
  width: 80%;
  overflow-y: scroll;
}
.annoucementsRight::-webkit-scrollbar {
  width: 10px;
}
.annoucementsRight::-webkit-scrollbar-thumb {
  background-color: var(--dark-gray);
  border-radius: 8px;
}

.annoucementsSearchbar {
  height: 45px;
  width: 100%;
  border: none;
  padding: 10px 30px 10px 40px;
  box-sizing: border-box;
  border-radius: 8px;
}
.annoucementsSearchbar:focus {
  outline: none;
}
.searchbarContainer {
  border-bottom: 1px solid var(--gray);
  position: relative;
}
.searchIcon {
  left: 10px;
  top: 10px;
  position: absolute;
}
.confirmationModalContent {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: white;
  width: 500px;
  height: 400px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.confirmationModalContent h3 {
  text-align: center;
}
.warningIconWrapper {
  width: 150px;
  height: 150px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  box-shadow: 8px 8px 27px -14px rgba(66, 68, 90, 1);
}
.btnsContainer {
  display: flex;
  width: 45%;
  height: 50px;
  margin-top: 15px;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .annoucementsWrapper {
    margin: 50px 0;
    height: auto;
    flex-direction: column;
  }
  .annoucementsSidebar {
    width: 100%;
    height: 30%;
    justify-content: center;
  }
  .annoucementsSidebar button {
    margin-top: 30px;
  }
  .topChip {
    border-radius: none;
    width: 100%;
  }
  .annoucementsRight {
    width: 100%;
    height: 70%;
  }
  .confirmationModalContent {
    width: 90%;
    height: 400px;
  }
  .btnsContainer {
    width: 70%;
  }
}
