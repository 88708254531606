.inputRoot {
  height: 50px;
  padding: 0 10px !important;
  margin-bottom: 11px;
}

.input {
  font-size: 15px;
}

.option {
  font-family: 'Quicksand', sans-serif;
}
