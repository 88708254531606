.reportsContainer {
  height: 655px;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 24px;
  box-shadow: 0px 4px 6px rgba(85, 85, 85, 0.08),
    0px 1px 20px rgba(0, 0, 0, 0.07), 0px 1px 11px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  display: flex;
}

@media screen and (max-width: 800px) {
  .reportsContainer {
    flex-direction: column;
    padding-bottom: 40px;
  }

  .reportsContainer > * {
    height: 50%;
  }
}
