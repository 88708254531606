.formWrapper {
  background: white;
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
}
.formHeader h1 {
  font-weight: 700;
  font-size: 15px;
  margin: 5px 0;
}
.select:hover {
  outline: none;
}
.inputCalendar {
  position: absolute;
  right: 15px;
  top: 20px;
  width: 16px;
  fill: var(--light-gray);
  stroke-width: 0.5;
}
