
.inputCalendar {
    position: absolute;
    right: 15px;
    top: 13px;
    width: 16px;
    fill: var(--light-gray);
    stroke-width: 0.5;
}
.dateInputLabelOnBorder {
    padding: 1px 8px;
    margin-left: -4px;
    color: rgba(0, 0, 0, 0.6);
    background-color: white;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    position: absolute;
    left: 0;
    top: 0;
    -ms-transform: translate(14px, -9px) scale(0.75);
    transform: translate(14px, -9px) scale(0.75);
    -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.datePickerContainer {
    box-sizing: border-box;
    position: relative;
    padding: 0 25px;
    font-size: 15px;
    width: 200px;
}
.selectContainer {
    box-sizing: border-box;
    padding: 0 25px;
    margin: 14px 0 5px 0;
    width: 500px;
    font-size: 15px;
}
